import Footer from '../components/Footer';
import useLocoScroll from '../hooks/useLocoScroll';

import UIForm from '../components/UIForm';

export default function Hablemos() {
    useLocoScroll(true);
    return (
        <>
            <div
                className="flex flex-col overflow-x-hidden bg-white lg:pt-[72px] md:pt-72px] pt-[60px]"
                id="main-container">
                <div
                    data-scroll-section
                    className="relative flex bg-negro grow  min-h-[200px] md:min-h-[600px] lg:min-h-[600px]">
                    <div className="flex flex-col-reverse  grow lg:flex-row bg-cover bg-center bg-no-repeat" style={{
                                backgroundImage:
                                    'url(/assets/images/banner-hablemos.jpg)',
                            }}>
                    
                   
                    </div>
                </div>

                <div
                    data-scroll-section
                    className=" z-10 flex flex-col justify-center gap-20 pb-10 mb-0 md:pb-24 md:flex-row bg-negro">
                    <div className="flex flex-col gap-4 md:mx-12 lg:mx-12 md:flex-row w-full md:-mt-0 justify-center items-center ">
                        <UIForm />
                        <div className="flex flex-col w-full px-7">
                            <div className="flex items-center gap-2 pb-6">
                                <a
                                    className="flex shrink-0 w-2/12"
                                    href="https://wa.me/51997949102"
                                    target="_blank"
                                    rel="noreferrer">
                                    <img className="w-4/6"
                                        alt="whatsapp"
                                        src="/assets/images/ico-whatsapp.png"></img>
                                </a>
                                <a
                                    className="font-tit2 uppercase md:text-[30px] text-[18px] text-verde"
                                    href="https://wa.me/51997949102"
                                    target="_blank"
                                    rel="noreferrer">
                                    Escríbenos al{' '}
                                    <span className="font-bold">
                                        Whatsapp de 121
                                    </span>
                                </a>
                            </div>
                            <div className="flex items-center gap-2">
                                <a
                                    className="flex shrink-0 w-2/12"
                                    href="mailto:rosanne.palhares@121rm.com?subject=Hola%20121"
                                    target="_blank"
                                    rel="noreferrer">
                                    <img className="w-4/6"
                                        src="/assets/images/ico-message.png"
                                        alt="correo"></img>
                                </a>
                                <a
                                    className="font-tit2 uppercase md:text-[30px] text-[18px]"
                                    href="mailto:rosanne.palhares@121rm.com?subject=Hola%20121"
                                    target="_blank"
                                    rel="noreferrer">
                                    <span className="text-amarillo">
                                        Escríbenos{' '}
                                        <span className="font-bold">
                                            un correo
                                        </span>
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div className="flex flex-col mt-20 ml-2 lg:gap-24 gap-14 lg:mt-20 md:mt-10 md:pl-9 lg:pl-1 md:gap-20"></div>
                    </div>
                </div>

                <div data-scroll-section>
                    <Footer custom next={'/*'} prev={'/soy121/'} />
                </div>
            </div>
        </>
    );
}
