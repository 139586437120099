import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import UIBurguerIcon from './UIBurguerIcon';

export default function NavigationTop(props) {
    const [responsive, setResponsive] = React.useState(false);
    const navigate = useNavigate()

    return (
        <React.Fragment>
            {responsive && (
                <div
                    className={`fixed top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center gap-6 overflow-hidden text-3xl font-bold tracking-tight  text-amarillo bg-negro z-60 ${
                        responsive ? 'animate-slide-down' : 'animate-slide-up'
                    }`}>
                    <Link to="/" onClick={() => {setResponsive(false); navigate("/")}}>Hola 121</Link>
                    <Link to="/trabajo" onClick={() => {setResponsive(false); navigate('/trabajo')}}>Trabajo</Link>
                    <Link to="/diferencia" onClick={() => {setResponsive(false); navigate('/diferencia')}}>La diferencia</Link>
                    <Link to="/soy121" onClick={() => {setResponsive(false); navigate('/soy121')}}>Soy 121</Link>
                    <Link to="/hablemos" onClick={() => {setResponsive(false); navigate('/hablemos')}}>Hablemos</Link>
                    <div
                        className="cursor-pointer"
                        onClick={() => setResponsive(false)}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-12 h-12">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </div>
                </div>
            )}
            <div className="fixed top-0 left-0 z-40 flex flex-grow w-full gap-4 px-1 py-2 transition-all duration-500 bg-yellow text-black md:py-4 md:hover:py-10 navigation-top ">
                <div className="mx-[40px] flex grow">
                    <NavLink
                        to={'/'}
                        className="flex items-end gap-2 pr-16 bg-opacity-0 bg-right bg-no-repeat hover:bg-opacity-100">
                        <img
                            src="/assets/images/logo121new.svg"
                            alt="logo"
                            className="h-10 md:h-10"
                        />
                    </NavLink>
                    <div className="flex flex-grow"></div>
                    <div className="items-center hidden md:flex navigation-top__menu">
                        <ul className="flex flex-row text-[15px] uppercase gap-12 font-sans text-sm font-bold tracking-wide shrink-0 ">
                            <li className="transition-all transform rounded-full hover:text-orange hover:bg-opacity-100">
                                <NavLink
                                    to="/"
                                    data-scroll-to
                                    className={({ isActive }) =>
                                        isActive
                                            ? 'border-b-2 pb-2 border-b-orange text-orange'
                                            : 'font-bold'
                                    }>
                                    Hola 121
                                </NavLink>
                            </li>
                            <li className="transition-all transform rounded-full hover:text-orange hover:bg-opacity-100 hover:animate-pulse ">
                                <NavLink
                                    to="/trabajo"
                                    data-scroll-to
                                    className={({ isActive }) =>
                                        isActive
                                            ? 'border-b-2 pb-2 border-b-orange text-orange'
                                            : 'font-bold'
                                    }>
                                    Trabajo
                                </NavLink>
                            </li>
                            <li className="transition-all transform rounded-full hover:text-orange hover:bg-opacity-100 hover:animate-pulse">
                                <NavLink
                                    to="/diferencia"
                                    data-scroll-to
                                    className={({ isActive }) =>
                                        isActive
                                            ? 'border-b-2 pb-2 border-b-orange text-orange'
                                            : 'font-bold'
                                    }>
                                    La Diferencia
                                </NavLink>
                            </li>
                            <li className="transition-all transform rounded-full hover:text-orange hover:bg-opacity-100 hover:animate-pulse">
                                <NavLink
                                    to="/soy121"
                                    data-scroll-to
                                    className={({ isActive }) =>
                                        isActive
                                            ? 'border-b-2 pb-2 border-b-orange text-orange'
                                            : 'font-bold'
                                    }>
                                    Soy 121
                                </NavLink>
                            </li>
                            <li className="transition-all transform rounded-full hover:text-orange hover:bg-opacity-100 hover:animate-pulse">
                                <NavLink
                                    to="/hablemos"
                                    data-scroll-to
                                    className={({ isActive }) =>
                                        isActive
                                            ? 'border-b-2 pb-2 border-b-orange text-orange'
                                            : 'font-bold'
                                    }>
                                    Hablemos
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <UIBurguerIcon
                        isResponsive={responsive}
                        onClick={() => setResponsive(!responsive)}
                        className="flex mt-2 md:hidden"
                    />
                </div>
            </div>
        </React.Fragment>
    );
}
