import axios from 'axios';
import { URL_BACKEND } from '../enviroment/enviroment';

export const GetLeadsAPI = async (limit = 100) => {
    try {
        const { data } = await axios.get(
            `${URL_BACKEND}/leads?pagination[limit]=${limit}`
        );
        return data;
    } catch (error) {
        console.log(error);
    }
};
