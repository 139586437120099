import React, { useEffect } from 'react';
import Footer from '../components/Footer';
import UIProjectGridCell from '../components/UIProjectGridCell';
import useLocoScroll from '../hooks/useLocoScroll';
import { GetProjectsByCategoryAPI } from '../utils/getProyectsbyCategory';

export default function Trabajo() {
    useLocoScroll(true);
    const [groupA, setgroupA] = React.useState([]);
    const [groupB, setgroupB] = React.useState([]);
    const [groupC, setgroupC] = React.useState([]);

    useEffect(() => {
        GetProjectsByCategoryAPI(4)
            .then((data) => {
                // reverse order bay attributes.order
                const orderedData = data.data.attributes.projects.data.sort(
                    (a, b) => a.attributes.orden - b.attributes.orden
                );
                setgroupA(orderedData);
                // setgroupA(data.data.attributes.projects.data);
            })
            .catch((error) => {
                console.log(error);
            });
        GetProjectsByCategoryAPI(2)
            .then((data) => {
                const orderedData = data.data.attributes.projects.data.sort(
                    (a, b) => a.attributes.orden - b.attributes.orden
                );
                setgroupB(orderedData);
            })
            .catch((error) => {
                console.log(error);
            });
        GetProjectsByCategoryAPI(3)
            .then((data) => {
                const orderedData = data.data.attributes.projects.data.sort(
                    (a, b) => a.attributes.orden - b.attributes.orden
                );
                setgroupC(orderedData);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return (
        <>
            <div
                className="flex flex-col overflow-x-hidden bg-white lg:pt-[72px] md:pt-[72px] pt-[72px]"
                id="main-container">
                <div
                    data-scroll-section
                    className="relative flex grow bg-negro lg:min-h-[600px] md:min-h-[600px] ">
                    <div className="flex flex-col-reverse grow lg:flex-row ">
                        <div className="flex flex-col lg:pl-20 md:pl-20 lg:pb-0 md:pb-0 pt-10 pb-10 justify-center justify-self-center gap-8 md:gap-5 tracking-tight lg:text-left md:text-center w-full sm:max-w-[540px] mx-auto md:max-w-[540px] lg:max-w-[665px] text-amarillo">
                            <p className="subpixel-antialiased font-tit2 font-bold  tracking-normal text-center leading-none lg:text-left md:justify-center  lg:text-[70px] md:text-[70px] text-[50px] lg:leading-[65px] md:leading-[65px] leading-[25px] ">
                                TRABAJO
                            </p>
                            <p className=" md:w-full font-thin leading-7 text-[20px] md:text-[25px] tracking-normal text-center md:text-center lg:text-left md:justify-center">
                                Una idea sorprendente es el camino <br />
                                más corto entre una marca y un <br />
                                consumidor que tiene cada vez menos <br />
                                tiempo para pensar en ella.
                            </p>
                        </div>
                        <div
                            className="relative w-full sm:max-w-100 mx-auto md:max-w-100 lg:max-w-100 justify-self-center bg-center bg-no-repeat bg-cover lg:aspect-video md:aspect-video aspect-[4/3]"
                            style={{
                                backgroundImage:
                                    'url(/assets/images/trabajo-banner.jpg)',
                            }}>
                                <img
                                    className="lg:block md:block hidden absolute h-[400px] top-1/2 transform -translate-x-1/2 -translate-y-1/2"
                                    src="/assets/images/121-03-01.png"
                                    alt="home"
                                />
                            {/* <AnimatedHeader2/> */}
                        </div>
                    </div>
                </div>

                <div
                    data-scroll-section
                    className="bg-white md:pb-10 md:pt-5 md:px-10 ">
                    <div className="grid md:gap-[20px] grid-cols-1 md:grid-cols-2">
                        {groupA.map((project, index) => (
                            <UIProjectGridCell
                                key={index}
                                id={project.id}
                                project={project.attributes}
                            />
                        ))}
                    </div>
                </div>

                <div
                    data-scroll-section
                    className="py-10 md:pb-52 bg-indigo md:pt-16">
                    <div className="flex flex-row items-center justify-start pb-4 mx-10 md:mx-32">
                        <div className="justify-end w-2/3 md:w-screen  md:pt-5 text-[14px] md:text-[26px] leading-tight font-semibold tracking-normal text-negro ">
                            Cada campaña es un desafio para detener el scroll
                            <br />
                            de los pulgares, convertir cada POST en un STOP
                            <br />
                        </div>
                        <div
                            data-scroll
                            data-scroll-speed="2"
                            data-scroll-direction="horizontal"
                            className=" w-1/3 scroll-pt-72 md:w-1/2 md:-mt-32 lg:max-w-[265px]">
                            <img
                                src="/assets/images/121-02.svg"
                                alt="mira121"></img>
                        </div>
                    </div>
                </div>

                <div
                    data-scroll-section
                    className="-mt-5 md:pb-10 md:pt-12 md:px-10 md:-mt-40 ">
                    <div className="grid grid-cols-1 md:gap-4 md:grid-cols-2">
                        {groupB.map((project, index) => (
                            <UIProjectGridCell
                                key={index}
                                id={project.id}
                                project={project.attributes}
                            />
                        ))}
                    </div>
                </div>

                <div
                    data-scroll-section
                    className="py-10 md:mt-16 md:pb-52 bg-orange">
                    <div className="flex flex-row justify-start mx-10 md:mx-32">
                        <div className="justify-end align-middle w-screen md:mt-16 lg:text-[26px] md:text-[30px] leading-tight font-semibold tracking-normal text-negro ">
                            Nuestra creatividad encuentra <br />
                            en la información y la tecnología <br />
                            los socios ideales para soprender <br />
                            y generar reaccciones.
                            <br />
                        </div>
                        <div
                            data-scroll
                            data-scroll-speed="2"
                            data-scroll-direction="horizontal"
                            className="w-1/3 scroll-pt-72 md:w-1/2 md:-mt-32 lg:max-w-[265px]">
                            <img
                                src="/assets/images/121-04.svg"
                                alt="michi121"></img>
                        </div>
                    </div>
                </div>

                <div data-scroll-section className=" md:px-10 md:-mt-40">
                    <div className="grid grid-cols-1 md:gap-4 md:grid-cols-2">
                        {groupC.map((project, index) => (
                            <UIProjectGridCell
                                key={index}
                                id={project.id}
                                project={project.attributes}
                            />
                        ))}
                    </div>
                </div>

                <div data-scroll-section>
                    <Footer custom next={'/diferencia/'} prev={'/*'} />
                </div>
            </div>
        </>
    );
}
