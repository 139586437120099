import axios from 'axios';
import { URL_BACKEND } from '../enviroment/enviroment';

export const GetProjectAPI = async (route) => {
    try {
        const { data } = await axios.get(
            `${URL_BACKEND}/projects?filters[route][$eq]=${route}`
        );
        return data;
    } catch (error) {
        console.log(error);
    }
};

export const GetProjecByID = async (id) => {
    try {
        const { data } = await axios.get(`${URL_BACKEND}/projects/${id}`);
        return data;
    } catch (error) {
        console.log(error);
    }
};

export const GetAllProjecs = async () => {
    try {
        const { data } = await axios.get(`${URL_BACKEND}/projects/`);
        return data;
    } catch (error) {
        console.log(error);
    }
};
