export const talent = [
    {
        attributes: {
            image: 'https://res.cloudinary.com/dgo7tcwqh/image/upload/v1726689633/121%20web/121%20Talent/lxo71vkdck0z6wynouqb.jpg',
            name: 'Alejandro Trípoli',
            charge: 'Past President',
        },
    },
    {
        attributes: {
            image: 'https://res.cloudinary.com/dgo7tcwqh/image/upload/v1726689633/121%20web/121%20Talent/mdp0eby0fgliaelzzn7k.jpg',
            name: 'Gonzalo Calmet ',
            charge: 'CEO',
        },
    },
    {
        attributes: {
            image: 'https://res.cloudinary.com/dgo7tcwqh/image/upload/v1726689634/121%20web/121%20Talent/x9tkzfsgh2yjeflbvo11.jpg',
            name: 'Lucas Bargen',
            charge: 'Director Creativo Ejecutivo',
        },
    },
    {
        attributes: {
            image: 'https://res.cloudinary.com/dgo7tcwqh/image/upload/v1726689634/121%20web/121%20Talent/x9fhphpl9yguwvogxvs1.jpg',
            name: 'Rosanne Palmares',
            charge: 'Directora de Nuevos Negocios',
        },
    },
    {
        attributes: {
            image: 'https://res.cloudinary.com/dgo7tcwqh/image/upload/v1726689633/121%20web/121%20Talent/n0ircoiijrnw92ghchcb.jpg',
            name: 'Clara Ferro',
            charge: 'Directora de Contenidos',
        },
    },
    {
        attributes: {
            image: 'https://res.cloudinary.com/dgo7tcwqh/image/upload/v1726689633/121%20web/121%20Talent/cfqwve2htzclaaqeumn5.jpg',
            name: 'Ana Lopez',
            charge: 'Directora de Administración',
        },
    },
    {
        attributes: {
            image: 'https://res.cloudinary.com/dgo7tcwqh/image/upload/v1726689634/121%20web/121%20Talent/ydqgljpsoovflu2fbtdh.jpg',
            name: 'Luis Ruiz',
            charge: 'Director de Producción',
        },
    },
    {
        attributes: {
            image: 'https://res.cloudinary.com/dgo7tcwqh/image/upload/v1726689633/121%20web/121%20Talent/st2xafc8sotkrzdqjfkk.jpg',
            name: 'Jose Luis Linares',
            charge: 'Director Creativo',
        },
    },
    {
        attributes: {
            image: 'https://res.cloudinary.com/dgo7tcwqh/image/upload/v1726689633/121%20web/121%20Talent/klh6rsilsmbgzlq2mj96.jpg',
            name: 'Alvaro Paitan',
            charge: 'Director Creativo',
        },
    },
    // {
    //     attributes: {
    //         image: 'v1670354388/121%20web/121%20Talent/Fiorella_Figueroa_rj6v45.png',
    //         name: 'Fiorella Figueroa',
    //         charge: 'Directora de RR.SS.',
    //     },
    // },
];

export const servicios = [
    {
        attributes: {
            name: 'GRANDES IDEAS',
            color: '#ffc333',
            textcolor: '#000000',
            order: 'md:order-1 order-2',
            description:
                'Ideamos campañas de comunicación para generar sorpresa y atraer la atención en múltiples medios y plataformas.',
        },
    },
    {
        attributes: {
            name: 'CONSULTORÍA ESTRATÉGICA',
            color: '#e04d3d',
            textcolor: '#000000',
            order: 'md:order-2 order-4',
            description:
                'Desarrollamos estrategias potentes para tu marca, para tu plan de comunicación y para tus redes scoiales.',
        },
    },
    {
        attributes: {
            name: 'GESTIÓN DE REDES',
            color: '#3ba6ab',
            textcolor: '#000000',
            order: 'md:order-3 order-1',
            description:
                'Creamos contenidos para tus redes y las gestionamos para construir engagement y mejorar el sentimento.',
        },
    },
    {
        attributes: {
            name: 'ANALÍTICA E INSIGHT',
            color: '#000000',
            textcolor: '#ffc333',
            order: 'md:order-4 order-3',
            description:
                'Análisis y reporting, sentimento y analítica predictiva, Machine Learning aplicado a los negocios.',
        },
    },
];
