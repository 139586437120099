
import axios from "axios";
import { URL_BACKEND } from "../enviroment/enviroment";

export const GetProjectsByCategoryAPI = async (id) => {
    try {
        const { data } = await axios.get(`${URL_BACKEND}/categories/${id}?populate=%2A`);
        return data;
    } catch (error) {
        console.log(error);
    }
}   
